import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  useFamiliaCristaFilesQuery,
  ensureFamiliaCristaFilesQueryData,
  invalidateFamiliaCristaFilesQuery,
} from "data/queries/queryFamiliaCristaFiles";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { apiPrefix } from "config/api-prefix";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import usePermissions from "shared/hooks/usePermissions";
import fetch from "data/fetch";

export async function loader() {
  return await ensureFamiliaCristaFilesQueryData();
}

export default function FamiliaCristaFilePage() {
  const { t } = useTranslation();
  const hasDeletePermission = usePermissions("create", "familia-crista-file");

  const handleDelete = async (id) => {
    if (!window.confirm(t("Do you confirm the deletion?"))) {
      return;
    }

    try {
      await fetch(`/v1/fc-file/${id}`, {
        method: "DELETE",
      });
      invalidateFamiliaCristaFilesQuery();
    } catch (error) {
      console.error("Error deleting file:", error);
      alert(t("Failed to delete the file. Please try again."));
    }
  };

  return (
    <IndexPageLayout
      useQuery={useFamiliaCristaFilesQuery}
      defaultSorting="-creationDate"
      hasSearch={true}
      header={
        <IndexPageHeader
          heading={t("Familia Crista Files")}
          routeIndex="/familia-crista/files"
          routeNew="/familia-crista/upload-file"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "createdAt", label: t("Creation Date") },
            { columnId: "type", label: t("Type") },
            { columnId: "description", label: t("Description") },
            { columnId: "estudoId", label: t("Study ID") },
            hasDeletePermission && { columnId: "actions", label: t("Actions") },
          ].filter(Boolean)}
          renderRow={({
            id,
            fileId,
            type,
            description,
            estudoId,
            creationDate,
          }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Link
                  href={apiPrefix(`/v1/file/${fileId}`)}
                  download={`familia_crista_${type}_${estudoId}_${fileId}`}
                >
                  <Typography variant="subtitle2" noWrap>
                    {moment(creationDate).format("DD/MM/YYYY")}
                  </Typography>
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {t(type)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {description}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {estudoId}
                </Typography>
              </TableCell>
              {hasDeletePermission && (
                <TableCell>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          )}
        />
      }
    />
  );
}
