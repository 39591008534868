import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchFriends4EverDepartments(page = 0, sort = "-data") {
  return fetch(
    `/v1/friends-4ver-departamentos?size=100&index=${page}&s=${sort}`,
  );
}

const getQueryKey = (index = 0, sort = "-data") => [
  "friends-4ever-departments",
  index,
  sort,
];

export function useFriends4EverDepartmentsQuery({
  page = 0,
  sort = "-data",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, sort),
    queryFn: () => fetchFriends4EverDepartments(page, sort),
  });
}

export function ensureFriends4EverDepartmentsQueryData({
  page = 0,
  sort = "-data",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, sort),
    queryFn: () => fetchFriends4EverDepartments(page, sort),
  });
}

export function invalidateFriends4EverDepartmentsQuery() {
  return queryClient.invalidateQueries(["friends-4ever-departments"]);
}
