import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchWarRoom(id) {
  return fetch(`/v1/quarto-guerra/${id}`);
}

const getQueryKey = (id) => ["quarto-guerra-detail", id];

export function useWarRoomDetailQuery({ id, ...other }) {
  return useQuery({
    queryKey: ["quarto-guerra", id],
    queryFn: () => fetch(`/v1/quarto-guerra/${id}`),
    ...other,
  });
}

export function ensureWarRoomDetailQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: ["quarto-guerra", id],
    queryFn: () => fetch(`/v1/quarto-guerra/${id}`),
  });
}

export function invalidateWarRoomDetailQuery() {
  return queryClient.invalidateQueries(["quarto-guerra-detail"]);
}
