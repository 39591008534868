import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import fetch from "data/fetch";
import { invalidateDeducoesQuery } from "data/queries/treasury/queryDeductions";

const DEDUCTION_TYPES = ["AJUDA_AO_NECESSITADO", "OUTROS"];

export default function TreasuryDeductionsNewPage({ layout }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: relatorioCultoSecretariaId } = useParams();
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [formData, setFormData] = useState({
    tipo: "AJUDA_AO_NECESSITADO",
    valor: "",
  });

  const handleSubmit = async () => {
    if (!formData.valor) {
      setError(t("Amount is required"));
      return;
    }

    setError(null);
    setIsSubmitting(true);

    try {
      await fetch("/v1/relatorio-culto-tesouraria-deducoes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            relatorioCultoSecretariaId,
            tipo: formData.tipo,
            valor: parseFloat(formData.valor),
          },
        ]),
      });

      invalidateDeducoesQuery();
      navigate(`/treasury/${relatorioCultoSecretariaId}`);
    } catch (e) {
      setError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    navigate(`/treasury/${relatorioCultoSecretariaId}`);
  };

  if (layout !== "dialog") {
    return null;
  }

  return (
    <Dialog
      fullScreen={!isLargeScreen}
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={handleClose}
    >
      <DialogTitle>{t("Add New Deduction")}</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Select
            value={formData.tipo}
            onChange={(e) => setFormData({ ...formData, tipo: e.target.value })}
            fullWidth
          >
            {DEDUCTION_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {t(type)}
              </MenuItem>
            ))}
          </Select>

          <TextField
            label={t("Amount")}
            type="text"
            fullWidth
            value={formData.valor}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*\.?\d{0,2}$/.test(value)) {
                setFormData({ ...formData, valor: value });
              }
            }}
            onFocus={(e) => e.target.select()}
          />

          <ErrorAlert error={error} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? t("Saving...") : t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
