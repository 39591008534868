// data/queries/queryFamiliaCristaFiles.js

import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";
import moment from "moment";

function fetchFamiliaCristaFiles(page = 0, filter = "", sort = "-createdAt") {
  // Calculate date 90 days ago
  const fromDate = moment().subtract(90, "days").format("YYYY-MM-DD");

  return fetch(
    `/v1/fc-file?size=1000&index=${page}&q=${encodeURIComponent(filter)}&s=${sort}&from=${fromDate}`,
  );
}

const getQueryKey = (index = 0, filter = "", sort = "-createdAt") => [
  "fc-files",
  index,
  filter,
  sort,
];

export function useFamiliaCristaFilesQuery({
  page = 0,
  filter = "",
  sort = "-createdAt",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchFamiliaCristaFiles(page, filter, sort),
  });
}

export function ensureFamiliaCristaFilesQueryData({
  page = 0,
  filter = "",
  sort = "-createdAt",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchFamiliaCristaFiles(page, filter, sort),
  });
}

export function invalidateFamiliaCristaFilesQuery() {
  return queryClient.invalidateQueries(["fc-files"]);
}
