import fetch from "data/fetch";
import { invalidateFriends4EverDepartmentsQuery } from "data/queries/friends-4ever/queryFriends4EverDepartments";
import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Friends4EverDepartmentForm from "../components/Friends4EverDepartmentForm.react";

export async function loader() {
  await ensureCongregationQueryData();
  return null;
}

export default function Friends4EverDepartmentsNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    setError(null);

    try {
      await fetch(`/v1/friends-4ver-departamentos`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      invalidateFriends4EverDepartmentsQuery();
      navigate("/friends-4ever/departments");
    } catch (e) {
      setError(e);
    }
  };

  return (
    <Friends4EverDepartmentForm
      onSubmit={onSubmit}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new Friends4Ever Department report")}
          links={[
            {
              name: "Friends4Ever Departments",
              to: "/friends-4ever/departments",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
