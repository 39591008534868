import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchDeducoes(id) {
  return fetch(`/v1/relatorio-culto-secretaria/${id}/deducoes`);
}

const getQueryKey = (id) => ["treasury-deducoes", id];

export function useDeducoesQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchDeducoes(id),
  });
}

export function ensureDeducoesQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchDeducoes(id),
  });
}

export function invalidateDeducoesQuery() {
  return queryClient.invalidateQueries(["treasury-deducoes"]);
}
