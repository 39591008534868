import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchRegionals(page = 0, filter = "", sort = "+name") {
  return fetch(
    `/v1/regional?size=100&index=${page}&name=${encodeURIComponent(filter)}&s=${sort}`,
  );
}

const getQueryKey = (index = 0, name = "", sort = "+name") => [
  "regionals",
  index,
  name,
  sort,
];

export function useRegionalsQuery({
  page = 0,
  filter = "",
  sort = "+name",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchRegionals(page, filter, sort),
  });
}

export function ensureRegionalsQueryData({
  page = 0,
  filter = "",
  sort = "+name",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchRegionals(page, filter, sort),
  });
}

export function invalidateRegionalsQuery() {
  return queryClient.invalidateQueries(["regionals"]);
}
