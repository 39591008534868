import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import NearMeIcon from "@mui/icons-material/NearMe";
import { Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
  useRef,
} from "react";
import { IconButton } from "@mui/material";
import { useCongregationsQuery } from "data/queries/congregation/queryCongregations";

export default function CongregationSelect({
  value,
  onChange,
  required,
  disabled,
  initialValue,
}) {
  const { t } = useTranslation();
  const { data: congregationsData } = useCongregationsQuery({
    page: 0,
    filter: "",
    sort: "+name",
  });

  const [selectedValue, setSelectedValue] = useState(
    initialValue || value || null,
  );

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  const getFullCongregation = (congregation) => {
    if (!congregation || !congregationsData?.data) return null;
    return (
      congregationsData.data.find((c) => c.id === congregation.id) ||
      congregation
    );
  };

  return (
    <Autocomplete
      value={getFullCongregation(selectedValue)}
      onChange={(_event, newValue) => {
        setSelectedValue(newValue);
        onChange?.(newValue);
      }}
      disabled={disabled}
      options={congregationsData?.data || []}
      getOptionLabel={(option) => option?.name || ""}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        <Grid {...props} component="li">
          <Typography variant="subtitle2" noWrap>
            {option.name}
          </Typography>
        </Grid>
      )}
      renderInput={(params) => (
        <TextField {...params} required={required} label={t("Congregation")} />
      )}
    />
  );
}

function useComponentId() {
  const id = useId();
  return `congregation-select-${id}`;
}

function useRegionalMap(data) {
  return useMemo(() => {
    const regionalMap = new Map();
    data.forEach((element) => {
      const regionalId = element.regional.id;
      if (!regionalMap.has(regionalId)) {
        regionalMap.set(regionalId, {
          id: regionalId,
          name: element.regional.name,
          country: element.country,
        });
      }
    });
    return regionalMap;
  }, [data]);
}
