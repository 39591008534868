import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { invalidatePastoralVisitsQuery } from "data/queries/queryPastoralVisits";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import PastoralVisitForm from "./components/PastoralVisitForm.react";
import fetch from "data/fetch";

export default function PastoralVisitNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    await fetch("/v1/visitas-pastorais", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    invalidatePastoralVisitsQuery();
    navigate("/pastoral-visits");
  };

  return (
    <PastoralVisitForm
      onSubmit={onSubmit}
      breadcrumbs={
        <Breadcrumbs
          heading={t("New Pastoral Visit")}
          links={[
            {
              name: t("Pastoral Visits"),
              to: "/pastoral-visits",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
