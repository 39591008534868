import { useLoaderData, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  ensureWarRoomDetailQueryData,
  invalidateWarRoomDetailQuery,
  useWarRoomDetailQuery,
} from "data/queries/queryWarRoom";
import {
  ensureWarRoomMembersQueryData,
  useWarRoomMembersQuery,
} from "data/queries/queryWarRoomMembers";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import WarRoomForm from "./components/WarRoomForm.react";
import fetch from "data/fetch";

export async function loader({ params: { id } }) {
  const response = await fetch(`/v1/quarto-guerra/${id}`);
  const membersResponse = await fetch(`/v1/quarto-guerra/${id}/membros`);

  console.log("API Response:", response.data); // Debug log

  return {
    id,
    warRoomId: id,
    // Match the exact structure expected by CongregationSelect
    congregation: response.data.congregacao
      ? {
          id: response.data.congregacao.id,
          name: response.data.congregacao.name,
          country: response.data.congregacao.country,
          regional: response.data.congregacao.regional,
        }
      : null,
    commander: response.data.comandante
      ? {
          id: response.data.comandante.id,
          name: response.data.comandante.name,
        }
      : null,
    meetingTime: response.data.horarioReuniao,
    members: membersResponse.data.map((m) => ({
      id: m.id,
      name: m.name,
      relationId: m.relationId,
    })),
  };
}

export default function WarRoomEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useLoaderData();
  const [error, setError] = useState(null);

  // Update query hooks with proper initial data structure
  const { data: warRoom } = useWarRoomDetailQuery({
    id: data.warRoomId,
    initialData: {
      data: {
        id: data.warRoomId,
        congregacao: data.congregation,
        comandante: data.commander,
        horarioReuniao: data.meetingTime,
      },
    },
  });

  const { data: members } = useWarRoomMembersQuery({
    id: data.warRoomId,
    initialData: {
      data: data.members,
    },
  });

  const onSubmit = async ({ members: newMembers = [] }) => {
    try {
      // Ensure we have arrays to work with
      const currentMembers = data?.members || [];
      newMembers = newMembers || [];

      // Find removed members - those that are in currentMembers but not in newMembers
      const removedMembers = currentMembers.filter(
        (oldMember) =>
          !newMembers.some((newMember) => newMember.id === oldMember.id),
      );

      // Find added members - those that are in newMembers but not in currentMembers
      const addedMembers = newMembers.filter(
        (newMember) =>
          !currentMembers.some((oldMember) => oldMember.id === newMember.id),
      );

      // Delete removed members
      for (const member of removedMembers) {
        if (member.relationId) {
          await fetch(`/v1/quarto-guerra-relation/${member.relationId}`, {
            method: "DELETE",
          });
        }
      }

      // Add new members
      for (const member of addedMembers) {
        if (member.id && data.warRoomId) {
          await fetch("/v1/quarto-guerra-relation", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              quartoGuerraId: data.warRoomId,
              pessoaId: member.id,
            }),
          });
        }
      }

      invalidateWarRoomDetailQuery();
      navigate("/war-room");
    } catch (err) {
      console.error("Error updating war room members:", err);
      setError(err);
    }
  };

  const onDelete = async () => {
    if (!window.confirm(t("Are you sure you want to delete this war room?"))) {
      return;
    }

    try {
      // Delete all member relations first
      for (const member of data.members) {
        if (member.relationId) {
          await fetch(`/v1/quarto-guerra-relation/${member.relationId}`, {
            method: "DELETE",
          });
        }
      }

      // Delete war room
      await fetch(`/v1/quarto-guerra/${data.warRoomId}`, {
        method: "DELETE",
      });

      invalidateWarRoomDetailQuery();
      navigate("/war-room");
    } catch (err) {
      console.error("Error deleting war room:", err);
      setError(err);
    }
  };

  return (
    <WarRoomForm
      data={{
        warRoomId: data.warRoomId,
        congregation: warRoom?.data?.congregacao || data.congregation,
        commander: warRoom?.data?.comandante || data.commander,
        meetingTime: warRoom?.data?.horarioReuniao || data.meetingTime,
        members: members?.data || data.members,
      }}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      isEdit={true}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit War Room")}
          links={[
            {
              name: t("War Room"),
              to: "/war-room",
            },
            { name: data?.commander?.name || "" },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
