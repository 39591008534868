import { useNavigate, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { invalidatePastoralVisitsQuery } from "data/queries/queryPastoralVisits";
import { ensurePastoralVisitQueryData } from "data/queries/queryPastoralVisit";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import PastoralVisitForm from "./components/PastoralVisitForm.react";
import fetch from "data/fetch";

export const loader = async ({ params: { id } }) => {
  return await ensurePastoralVisitQueryData(id);
};

export default function PastoralVisitEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useLoaderData();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      await fetch(`/v1/visitas-pastorais/${data.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      invalidatePastoralVisitsQuery();
      navigate("/pastoral-visits");
    } catch (err) {
      setError(err);
    }
  };

  const onDelete = async () => {
    if (!window.confirm(t("Do you confirm the deletion?"))) {
      return;
    }

    try {
      await fetch(`/v1/visitas-pastorais/${data.id}`, {
        method: "DELETE",
      });

      invalidatePastoralVisitsQuery();
      navigate("/pastoral-visits");
    } catch (err) {
      setError(err);
    }
  };

  return (
    <PastoralVisitForm
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      isEdit={true}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit Pastoral Visit")}
          links={[
            {
              name: t("Pastoral Visits"),
              to: "/pastoral-visits",
            },
            {
              name: data.pessoa.name,
              to: `/pastoral-visits/${data.id}/view`,
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
