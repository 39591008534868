import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  usePastoralVisitsQuery,
  ensurePastoralVisitsQueryData,
} from "data/queries/queryPastoralVisits";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export async function loader() {
  return await ensurePastoralVisitsQueryData();
}

export default function PastoralVisitsIndexPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={usePastoralVisitsQuery}
      defaultSorting="-data"
      header={
        <IndexPageHeader
          heading={t("Pastoral Visits")}
          routeIndex="/pastoral-visits"
          routeNew="/pastoral-visits/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "data", label: t("Date") },
            { columnId: "pastor.name", label: t("Pastor") },
            { columnId: "pessoa.name", label: t("Person") },
            { columnId: "comentario", label: t("Comment") },
          ]}
          renderRow={(visit) => (
            <TableRow
              hover
              key={visit.id}
              component={RouterLink}
              to={`/pastoral-visits/${visit.id}/edit`}
            >
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/pastoral-visits/${visit.id}/edit`}
                >
                  {visit.data}
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {visit.pastor.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {visit.pessoa.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {visit.comentario}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
