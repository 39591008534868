import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchPastoralVisits(page = 0, filter = "", sort = "-data") {
  return fetch(
    `/v1/visitas-pastorais?size=100&index=${page}&name=${encodeURIComponent(
      filter,
    )}&s=${sort}`,
  );
}

const getQueryKey = (index = 0, name = "", sort = "-data") => [
  "visitas-pastorais",
  index,
  name,
  sort,
];

export function usePastoralVisitsQuery({
  page = 0,
  filter = "",
  sort = "-data",
  ...other
}) {
  return useQuery({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchPastoralVisits(page, filter, sort),
    ...other,
  });
}

export function ensurePastoralVisitsQueryData({
  page = 0,
  filter = "",
  sort = "-data",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchPastoralVisits(page, filter, sort),
  });
}

export function invalidatePastoralVisitsQuery() {
  return queryClient.invalidateQueries(["visitas-pastorais"]);
}
