import { useParams, useNavigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useTreasuryQuery,
  ensureTreasuryQueryData,
} from "data/queries/treasury/queryTreasury";
import {
  useDeducoesQuery,
  invalidateDeducoesQuery,
} from "data/queries/treasury/queryDeductions";
import fetch from "data/fetch";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import formatNumber from "shared/utils/formatNumber";

export async function loader({ params }) {
  const { id } = params;
  if (!id) {
    throw new Error("Invalid ID provided.");
  }
  return ensureTreasuryQueryData(id);
}

export default function TreasuryIndexPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: queryData } = useTreasuryQuery({ id });
  const { data: deducoesData } = useDeducoesQuery({ id });
  const treasuryData = queryData?.data;
  const deducoes = deducoesData?.data ?? [];

  const handleDelete = async (entryId) => {
    if (!window.confirm(t("Are you sure you want to delete this entry?"))) {
      return;
    }
    try {
      await fetch(`/v1/relatorio-culto-tesouraria/${entryId}`, {
        method: "DELETE",
      });
      await ensureTreasuryQueryData(id); // Refresh data after deletion
    } catch (error) {
      alert(t("Failed to delete the entry. Please try again."));
    }
  };

  const handleDeleteDeducao = async (deducaoId) => {
    if (!window.confirm(t("Are you sure you want to delete this deduction?"))) {
      return;
    }
    try {
      await fetch(`/v1/relatorio-culto-tesouraria-deducoes/${deducaoId}`, {
        method: "DELETE",
      });
      invalidateDeducoesQuery();
    } catch (error) {
      alert(t("Failed to delete the deduction. Please try again."));
    }
  };

  // Calculate the final total (financial subtotal - deductions)
  const financialSubtotal = treasuryData.reduce(
    (sum, entry) =>
      sum +
      (entry.oferta || 0) +
      (entry.dizimo || 0) +
      (entry.ofertaMissao || 0) +
      (entry.ofertaEBD || 0) +
      (entry.ofertaFC || 0) +
      (entry.ofertaPrimicias || 0) +
      (entry.ofertaOutras || 0),
    0,
  );

  const deductionsTotal = deducoes.reduce(
    (sum, deducao) => sum + (deducao.valor || 0),
    0,
  );
  const finalTotal = financialSubtotal - deductionsTotal;

  if (!treasuryData || treasuryData.length === 0) {
    return (
      <Container>
        <Outlet />
        <DetailsPageHeader
          heading={t("Treasury Report")}
          label={t("Report Details")}
          routeIndex="/treasury"
        />
        <Alert severity="info">
          <Stack spacing={2}>
            <div>{t("No treasury data found.")}</div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/treasury/${id}/new`)}
            >
              {t("Start New Treasury Report")}
            </Button>
          </Stack>
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Outlet />
      <DetailsPageHeader
        heading={t("Treasury Report")}
        label={t("Report Details")}
        routeIndex="/treasury"
      />

      {treasuryData && (
        <Stack spacing={4}>
          <FormCard title={t("Details")}>
            <dl>
              <dt>{t("Congregation")}</dt>
              <dd>
                {treasuryData[0]?.relatorioCultoSecretaria.congregacao.name ??
                  t("N/A")}
              </dd>

              <dt>{t("Report Date")}</dt>
              <dd>
                {moment(treasuryData[0]?.relatorioCultoSecretaria.data).format(
                  "MMM/DD/YYYY",
                )}
              </dd>

              <dt>{t("Service Type")}</dt>
              <dd>
                {t(treasuryData[0]?.relatorioCultoSecretaria.tipoCulto) ??
                  t("N/A")}
              </dd>
            </dl>
          </FormCard>

          <Card>
            <CardHeader
              title={t("Financial Details")}
              action={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(`/treasury/${id}/new`)}
                >
                  {t("Add New Registry")}
                </Button>
              }
            />
            <CardContent as={Stack} spacing={3}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Name")}</TableCell>
                      <TableCell>{t("Total")}</TableCell>
                      <TableCell>{t("Tithe")}</TableCell>
                      <TableCell>{t("Offering")}</TableCell>
                      <TableCell>{t("Mission Offering")}</TableCell>
                      <TableCell>{t("EBD Offering")}</TableCell>
                      <TableCell>{t("FC Offering")}</TableCell>
                      <TableCell>{t("Firstfruits Offering")}</TableCell>
                      <TableCell>{t("Other Offerings")}</TableCell>
                      <TableCell>{t("Actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {treasuryData.map((entry) => (
                      <TableRow key={entry.id}>
                        <TableCell>
                          {entry.pessoa?.name ?? t("Anonymous")}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {formatNumber(
                            entry.oferta +
                              entry.dizimo +
                              entry.ofertaMissao +
                              entry.ofertaEBD +
                              entry.ofertaFC +
                              entry.ofertaPrimicias +
                              entry.ofertaOutras,
                          )}
                        </TableCell>
                        <TableCell>{formatNumber(entry.dizimo)}</TableCell>
                        <TableCell>{formatNumber(entry.oferta)}</TableCell>
                        <TableCell>
                          {formatNumber(entry.ofertaMissao)}
                        </TableCell>
                        <TableCell>{formatNumber(entry.ofertaEBD)}</TableCell>
                        <TableCell>{formatNumber(entry.ofertaFC)}</TableCell>
                        <TableCell>
                          {formatNumber(entry.ofertaPrimicias)}
                        </TableCell>
                        <TableCell>
                          {formatNumber(entry.ofertaOutras)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label={t("Delete")}
                            onClick={() => handleDelete(entry.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}

                    {/* Total Row */}
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {t("Total")}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(financialSubtotal)}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(
                          treasuryData.reduce(
                            (sum, entry) => sum + (entry.dizimo || 0),
                            0,
                          ),
                        )}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(
                          treasuryData.reduce(
                            (sum, entry) => sum + (entry.oferta || 0),
                            0,
                          ),
                        )}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(
                          treasuryData.reduce(
                            (sum, entry) => sum + (entry.ofertaMissao || 0),
                            0,
                          ),
                        )}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(
                          treasuryData.reduce(
                            (sum, entry) => sum + (entry.ofertaEBD || 0),
                            0,
                          ),
                        )}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(
                          treasuryData.reduce(
                            (sum, entry) => sum + (entry.ofertaFC || 0),
                            0,
                          ),
                        )}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(
                          treasuryData.reduce(
                            (sum, entry) => sum + (entry.ofertaPrimicias || 0),
                            0,
                          ),
                        )}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(
                          treasuryData.reduce(
                            (sum, entry) => sum + (entry.ofertaOutras || 0),
                            0,
                          ),
                        )}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              title={t("Deductions")}
              action={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(`/treasury/${id}/deductions/new`)}
                >
                  {t("Add New Deduction")}
                </Button>
              }
            />
            <CardContent as={Stack} spacing={3}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Type")}</TableCell>
                      <TableCell>{t("Amount")}</TableCell>
                      <TableCell>{t("Actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deducoes.map((deducao) => (
                      <TableRow key={deducao.id}>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {t(deducao.tipo)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {formatNumber(deducao.valor)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            edge="end"
                            aria-label={t("Delete")}
                            onClick={() => handleDeleteDeducao(deducao.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}

                    {/* Total Row */}
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {t("Total")}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {formatNumber(deductionsTotal)}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6">
                  {t("Financial Subtotal")}: {formatNumber(financialSubtotal)}
                </Typography>
                <Typography variant="h6" color="error">
                  {t("Deductions Total")}: -{formatNumber(deductionsTotal)}
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {t("Final Total")}: {formatNumber(finalTotal)}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      )}
    </Container>
  );
}

function FormCard({ title, children }) {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent as={Stack} spacing={3}>
        {children}
      </CardContent>
    </Card>
  );
}

function FinancialTable({ data, onDelete }) {
  const { t } = useTranslation();

  // Calculate totals for each column
  const totals = {
    oferta: data.reduce((sum, entry) => sum + (entry.oferta || 0), 0),
    dizimo: data.reduce((sum, entry) => sum + (entry.dizimo || 0), 0),
    ofertaMissao: data.reduce(
      (sum, entry) => sum + (entry.ofertaMissao || 0),
      0,
    ),
    ofertaEBD: data.reduce((sum, entry) => sum + (entry.ofertaEBD || 0), 0),
    ofertaFC: data.reduce((sum, entry) => sum + (entry.ofertaFC || 0), 0),
    ofertaPrimicias: data.reduce(
      (sum, entry) => sum + (entry.ofertaPrimicias || 0),
      0,
    ),
    ofertaOutras: data.reduce(
      (sum, entry) => sum + (entry.ofertaOutras || 0),
      0,
    ),
  };

  // Calculate subtotal
  const subtotal = Object.values(totals).reduce((sum, value) => sum + value, 0);

  const calculateRowTotal = (entry) => {
    return (
      (entry.oferta || 0) +
      (entry.dizimo || 0) +
      (entry.ofertaMissao || 0) +
      (entry.ofertaEBD || 0) +
      (entry.ofertaFC || 0) +
      (entry.ofertaPrimicias || 0) +
      (entry.ofertaOutras || 0)
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Name")}</TableCell>
            <TableCell>{t("Total")}</TableCell>
            <TableCell>{t("Tithe")}</TableCell>
            <TableCell>{t("Offering")}</TableCell>
            <TableCell>{t("Mission Offering")}</TableCell>
            <TableCell>{t("EBD Offering")}</TableCell>
            <TableCell>{t("FC Offering")}</TableCell>
            <TableCell>{t("Firstfruits Offering")}</TableCell>
            <TableCell>{t("Other Offerings")}</TableCell>
            <TableCell>{t("Actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((entry) => (
            <TableRow key={entry.id}>
              <TableCell>{entry.pessoa?.name ?? t("Anonymous")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {formatNumber(calculateRowTotal(entry))}
              </TableCell>
              <TableCell>{formatNumber(entry.dizimo)}</TableCell>
              <TableCell>{formatNumber(entry.oferta)}</TableCell>
              <TableCell>{formatNumber(entry.ofertaMissao)}</TableCell>
              <TableCell>{formatNumber(entry.ofertaEBD)}</TableCell>
              <TableCell>{formatNumber(entry.ofertaFC)}</TableCell>
              <TableCell>{formatNumber(entry.ofertaPrimicias)}</TableCell>
              <TableCell>{formatNumber(entry.ofertaOutras)}</TableCell>
              <TableCell>
                <IconButton
                  aria-label={t("Delete")}
                  onClick={() => onDelete(entry.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

          {/* Total Row */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>{t("Total")}</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatNumber(subtotal)}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatNumber(totals.dizimo)}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatNumber(totals.oferta)}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatNumber(totals.ofertaMissao)}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatNumber(totals.ofertaEBD)}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatNumber(totals.ofertaFC)}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatNumber(totals.ofertaPrimicias)}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatNumber(totals.ofertaOutras)}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
