import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import fetch from "data/fetch";
import { invalidatePrayerRequestQuery } from "data/queries/queryPrayerRequestAll";

const PrayerType = {
  SAUDE: "SAUDE",
  JUSTICA: "JUSTICA",
  IMIGRACAO: "IMIGRACAO",
  FAMILIA: "FAMILIA",
  VIDA_ESPIRITUAL: "VIDA_ESPIRITUAL",
  SALVACAO: "SALVACAO",
  RELACIONAMENTO: "RELACIONAMENTO",
  MINISTERIO: "MINISTERIO",
  IGREJA: "IGREJA",
};

const ContactMethod = {
  EMAIL: "EMAIL",
  PHONE: "PHONE",
};

export async function action({ request }) {
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);

  await fetch("/v1/pedido-oracao", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updates),
  });

  return null;
}

export default function PrayerRequestNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [congregation, setCongregation] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [prefContact, setPrefContact] = useState(ContactMethod.EMAIL);
  const [tipo, setTipo] = useState("");
  const [pedido, setPedido] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await fetch("/v1/pedido-oracao", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          congregacaoId: congregation?.id,
          name,
          email,
          phone,
          prefContact,
          tipo,
          pedido,
        }),
      });

      invalidatePrayerRequestQuery();
      navigate("/prayer-requests");
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Breadcrumbs
        heading={t("New Prayer Request")}
        links={[
          {
            name: t("Prayer Requests"),
            to: "/prayer-requests",
          },
          { name: t("New") },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <Card component="form" onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <CongregationSelect
            required={true}
            value={congregation}
            onChange={setCongregation}
          />

          <TextField
            required
            label={t("Name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            required
            type="email"
            label={t("Email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            required
            label={t("Phone")}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <FormControl required>
            <InputLabel>{t("Preferred Contact Method")}</InputLabel>
            <Select
              value={prefContact}
              onChange={(e) => setPrefContact(e.target.value)}
            >
              <MenuItem value={ContactMethod.EMAIL}>{t("Email")}</MenuItem>
              <MenuItem value={ContactMethod.PHONE}>{t("Phone")}</MenuItem>
            </Select>
          </FormControl>

          <FormControl required>
            <InputLabel>{t("Prayer Type")}</InputLabel>
            <Select value={tipo} onChange={(e) => setTipo(e.target.value)}>
              <MenuItem value={PrayerType.SAUDE}>{t("Health")}</MenuItem>
              <MenuItem value={PrayerType.JUSTICA}>{t("Justice")}</MenuItem>
              <MenuItem value={PrayerType.IMIGRACAO}>
                {t("Immigration")}
              </MenuItem>
              <MenuItem value={PrayerType.FAMILIA}>{t("Family")}</MenuItem>
              <MenuItem value={PrayerType.VIDA_ESPIRITUAL}>
                {t("Spiritual Life")}
              </MenuItem>
              <MenuItem value={PrayerType.SALVACAO}>{t("Salvation")}</MenuItem>
              <MenuItem value={PrayerType.RELACIONAMENTO}>
                {t("Relationship")}
              </MenuItem>
              <MenuItem value={PrayerType.MINISTERIO}>{t("Ministry")}</MenuItem>
              <MenuItem value={PrayerType.IGREJA}>{t("Church")}</MenuItem>
            </Select>
          </FormControl>

          <TextField
            required
            multiline
            rows={4}
            label={t("Prayer Request")}
            value={pedido}
            onChange={(e) => setPedido(e.target.value)}
          />

          <ErrorAlert error={error} />

          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? t("Saving...") : t("Save")}
          </Button>
        </Stack>
      </Card>
    </Container>
  );
}
