import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";

export default function WarRoomAnalyticsPage() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <Breadcrumbs
        heading={t("Analytics")}
        links={[
          { name: t("War Room"), href: "/war-room" },
          { name: t("Analytics") },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      {/* Analytics content will go here */}
    </Container>
  );
}
