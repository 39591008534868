import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useActionData } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import RegionalSelect from "shared/components/RegionalSelect/RegionalSelect.react";
import PastorSelect from "shared/components/PastorSelect/PastorSelect.react";
import fetch from "data/fetch";
import { CountrySelector } from "shared/components/CountrySelector/CountrySelector.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";

export default function CongregationForm({
  breadcrumbs,
  data,
  onSubmit: onSubmitProp,
  onDelete: onDeleteProp,
  error: errorProp,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [name, setName] = useState(data?.name ?? "");
  const [country, setCountry] = useState(data?.country ?? "");
  const [address, setAddress] = useState(data?.address ?? "");
  const [regional, setRegional] = useState(
    data?.regionalId
      ? {
          id: data.regionalId,
          name: data.regionalName,
        }
      : null,
  );
  const [pastor, setPastor] = useState(
    data?.pastorId
      ? {
          id: data.pastorId,
          name: data.pastorName,
        }
      : null,
  );
  const [latitude, setLatitude] = useState(data?.latitude ?? "");
  const [longitude, setLongitude] = useState(data?.longitude ?? "");
  const [timeZoneId, setTimeZoneId] = useState(data?.timeZoneId ?? ""); // New field for timeZoneId
  const [timeZones, setTimeZones] = useState([]); // List of time zones
  const actionData = useActionData();

  useEffect(() => {
    // Fetch time zones on component mount
    const fetchTimeZones = async () => {
      try {
        const response = await fetch("/v1/time-zone");
        setTimeZones(response.data || []);
      } catch (error) {
        console.error("Failed to fetch time zones:", error);
      }
    };

    fetchTimeZones();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    onSubmitProp({
      name,
      country,
      address,
      regionalId: regional?.id,
      pastorId: pastor?.id,
      latitude,
      longitude,
      timeZoneId, // Include timeZoneId in the submission
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const onDelete = () => {
    if (!window.confirm(t("Do you confirm the deletion?"))) {
      return false;
    }

    setIsDeleting(true);

    onDeleteProp().finally(() => {
      setIsDeleting(false);
    });
  };

  return (
    <Container component="form" method="post" onSubmit={onSubmit}>
      {breadcrumbs}
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          <TextField
            label={t("Name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          {/* Replace TextField for Country with CountrySelect */}
          <CountrySelector
            label={t("Country")}
            value={country}
            onChange={(newCountry) => setCountry(newCountry)}
            required
          />
          <TextField
            label={t("Address")}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <RegionalSelect value={regional} onChange={setRegional} required />
          <PastorSelect value={pastor} onChange={setPastor} required />
          <TextField
            label={t("Latitude")}
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
          />
          <TextField
            label={t("Longitude")}
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
          />
          <FormControl fullWidth required>
            <InputLabel>{t("Time Zone")}</InputLabel>
            <Select
              value={timeZoneId}
              onChange={(e) => setTimeZoneId(e.target.value)}
            >
              {timeZones.map((zone) => (
                <MenuItem key={zone.id} value={zone.id}>
                  {zone.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ErrorAlert error={errorProp} />
          <ErrorAlert error={actionData?.error} />
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            {data?.congregationId && onDeleteProp && (
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                color="error"
                size="large"
                disabled={isDeleting}
                onClick={onDelete}
              >
                {isDeleting ? t("Deleting...") : t("Delete")}
              </Button>
            )}
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
}
