import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  usePrayerRequestQuery,
  ensurePrayerRequestQueryData,
} from "data/queries/queryPrayerRequestAll";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

export async function loader() {
  return await ensurePrayerRequestQueryData();
}

export default function PrayerRequestIndexPage() {
  const { t } = useTranslation();

  const getStatusColor = (status) => {
    switch (status) {
      case "EM_ORACAO":
        return "warning";
      case "RESPONDIDA":
        return "success";
      case "AGUARDANDO":
        return "info";
      case "ORADO":
        return "success";
      default:
        return "default";
    }
  };

  return (
    <IndexPageLayout
      useQuery={usePrayerRequestQuery}
      hasSearch={true}
      defaultSorting="-name"
      header={
        <IndexPageHeader
          heading={t("Prayer Requests")}
          routeIndex="/prayer-requests"
          routeNew="/prayer-requests/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "name", label: t("Name") },
            { columnId: "tipo", label: t("Prayer Type") },
            { columnId: "status", label: t("Status") },
            { columnId: "pedido", label: t("Request") },
          ]}
          renderRow={({ id, name, tipo, status, pedido }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Link component={RouterLink} to={`/prayer-requests/${id}/edit`}>
                  {name}
                </Link>
              </TableCell>
              <TableCell>{t(tipo)}</TableCell>
              <TableCell>
                <Chip
                  label={t(status)}
                  color={getStatusColor(status)}
                  size="small"
                />
              </TableCell>
              <TableCell>{pedido}</TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
