import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchWarRoomMembers(id) {
  return fetch(`/v1/quarto-guerra/${id}/membros`);
}

const getQueryKey = (id) => ["quarto-guerra", id, "members"];

export function useWarRoomMembersQuery({ id, ...other }) {
  return useQuery({
    queryKey: ["quarto-guerra", id, "members"],
    queryFn: () => fetch(`/v1/quarto-guerra/${id}/membros`),
    ...other,
  });
}

export function ensureWarRoomMembersQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: ["quarto-guerra", id, "members"],
    queryFn: () => fetch(`/v1/quarto-guerra/${id}/membros`),
  });
}

export function invalidateWarRoomMembersQuery(id) {
  return queryClient.invalidateQueries(["quarto-guerra", id, "members"]);
}
