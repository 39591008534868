import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useWarRoomListQuery,
  ensureWarRoomListQueryData,
} from "data/queries/queryWarRoomAll";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export async function loader() {
  return await ensureWarRoomListQueryData();
}

export default function WarRoomIndexPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useWarRoomListQuery}
      hasSearch={false}
      defaultSorting="+comandante.name"
      header={
        <IndexPageHeader
          heading={t("War Room")}
          routeIndex="/war-room"
          routeNew="/war-room/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "congregacao.name", label: t("Congregation") },
            { columnId: "comandante.name", label: t("Commander") },
            { columnId: "horarioReuniao", label: t("Meeting Time") },
          ]}
          renderRow={({ id, congregacao, comandante, horarioReuniao }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {congregacao.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Link component={RouterLink} to={`/war-room/${id}/view`}>
                  {comandante.name}
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {horarioReuniao}
                </Typography>
              </TableCell>
              <TableCell key="actions"></TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
