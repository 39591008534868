import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { usePastoralVisitQuery } from "data/queries/queryPastoralVisit";

export default function PastoralVisitViewPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: visit } = usePastoralVisitQuery({ id });

  return (
    <Container maxWidth="lg">
      <DetailsPageHeader
        heading={t("Pastoral Visit")}
        routeIndex="/pastoral-visits"
        routeUpdate={`/pastoral-visits/${id}/edit`}
        links={[
          {
            name: t("Pastoral Visits"),
            to: "/pastoral-visits",
          },
          { name: visit?.data?.pessoa?.name ?? "..." },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography>
              <strong>{t("Date")}:</strong> {visit?.data?.data}
            </Typography>
            <Typography>
              <strong>{t("Pastor")}:</strong> {visit?.data?.pastor?.name}
            </Typography>
            <Typography>
              <strong>{t("Person")}:</strong> {visit?.data?.pessoa?.name}
            </Typography>
            <Typography>
              <strong>{t("Comment")}:</strong> {visit?.data?.comentario}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}
