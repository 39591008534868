import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { usePeopleQuery } from "data/queries/queryPeople";

export default function PastorSelect({
  value,
  onChange,
  required,
  disabled,
  initialValue,
}) {
  const { t } = useTranslation();
  const { data: peopleData } = usePeopleQuery({
    page: 0,
    filter: "",
    sort: "+name",
    list: "LIDER_ABOVE", // Filter for pastors only
  });

  const [selectedValue, setSelectedValue] = useState(
    initialValue || value || null,
  );

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  const pastors = peopleData?.data || [];

  return (
    <Autocomplete
      value={selectedValue}
      onChange={(_event, newValue) => {
        setSelectedValue(newValue);
        onChange?.(newValue);
      }}
      disabled={disabled}
      options={pastors}
      getOptionLabel={(option) => option?.name || ""}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        <Grid {...props} component="li">
          <Typography variant="subtitle2" noWrap>
            {option.name}
          </Typography>
        </Grid>
      )}
      renderInput={(params) => (
        <TextField {...params} required={required} label={t("Pastor")} />
      )}
    />
  );
}
