import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import {
  ensureWarRoomDetailQueryData,
  useWarRoomDetailQuery,
} from "data/queries/queryWarRoom";
import { invalidateWarRoomPrayerRequestsQuery } from "data/queries/queryWarRoomPrayerRequests";

import AddIcon from "@mui/icons-material/Add";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import fetch from "data/fetch";

const PRAYER_TYPES = {
  SAUDE: "Health",
  JUSTICA: "Justice",
  IMIGRACAO: "Immigration",
  FAMILIA: "Family",
  VIDA_ESPIRITUAL: "Spiritual Life",
  SALVACAO: "Salvation",
  RELACIONAMENTO: "Relationship",
  MINISTERIO: "Ministry",
  IGREJA: "Church",
};

export async function loader({ params: { id } }) {
  return await ensureWarRoomDetailQueryData(id);
}

export default function WarRoomPrayerRequestNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Form state
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");

  const { data: warRoom } = useWarRoomDetailQuery({ id });

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await fetch("/v1/quarto-guerra-motivos-oracao", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quartoGuerraId: id,
          tipo: type,
          pedido: description,
        }),
      });

      invalidateWarRoomPrayerRequestsQuery(id);
      navigate(`/war-room/${id}/view`);
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Breadcrumbs
        heading={t("New Prayer Request")}
        links={[
          {
            name: t("War Room"),
            to: "/war-room",
          },
          {
            name: warRoom?.data?.comandante?.name,
            to: `/war-room/${id}/view`,
          },
          { name: t("New Prayer Request") },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <Card component="form" onSubmit={onSubmit}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <FormControl required>
            <InputLabel>{t("Prayer Type")}</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              label={t("Prayer Type")}
            >
              {Object.entries(PRAYER_TYPES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {t(label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            required
            multiline
            rows={4}
            label={t("Description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Stack spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Saving...") : t("Save")}
            </Button>

            {error && <ErrorAlert error={error} />}
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
}
