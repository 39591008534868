import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchWarRoomPrayerRequests(id) {
  return fetch(`/v1/quarto-guerra/${id}/pedidos?size=1000`);
}

const getQueryKey = (id) => ["quarto-guerra", id, "pedidos"];

export function useWarRoomPrayerRequestsQuery({ id, ...other }) {
  return useQuery({
    queryKey: getQueryKey(id),
    queryFn: () => fetchWarRoomPrayerRequests(id),
    ...other,
  });
}

export function ensureWarRoomPrayerRequestsQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchWarRoomPrayerRequests(id),
  });
}

export function invalidateWarRoomPrayerRequestsQuery(id) {
  return queryClient.invalidateQueries([
    "quarto-guerra",
    id,
    "prayer-requests",
  ]);
}
