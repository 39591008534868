import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchWarRooms(page = 0, filter = "", sort = "+comandante.name") {
  return fetch(
    `/v1/quarto-guerra?size=100&index=${page}&name=${encodeURIComponent(filter)}&s=${sort}`,
  );
}

const getQueryKey = (index = 0, name = "", sort = "+comandante.name") => [
  "quarto-guerra",
  index,
  name,
  sort,
];

export function useWarRoomListQuery({
  page = 0,
  filter = "",
  sort = "+comandante.name",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchWarRooms(page, filter, sort),
  });
}

export function ensureWarRoomListQueryData({
  page = 0,
  filter = "",
  sort = "+comandante.name",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchWarRooms(page, filter, sort),
  });
}

export function invalidateWarRoomQuery() {
  return queryClient.invalidateQueries(["quarto-guerra"]);
}
