import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchPastoralVisit(id) {
  return fetch(`/v1/visitas-pastorais/${id}`).then((response) => ({
    id,
    data: response.data.data,
    pastor: response.data.pastor,
    pessoa: {
      id: response.data.pessoa.id,
      name: response.data.pessoa.name,
    },
    comentario: response.data.comentario,
  }));
}

const getQueryKey = (id) => ["pastoral-visit", id];

export function usePastoralVisitQuery({ id }) {
  return useQuery({
    queryKey: getQueryKey(id),
    queryFn: () => fetchPastoralVisit(id),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

export async function ensurePastoralVisitQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchPastoralVisit(id),
  });
}

export function setPastoralVisitQueryData(id, data) {
  return queryClient.setQueryData(getQueryKey(id), data);
}

export function invalidatePastoralVisitQuery(id) {
  return queryClient.invalidateQueries(getQueryKey(id));
}
