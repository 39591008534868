import React, { useState } from "react";
import { Container, Card, Stack, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";

export default function PrayerRequestForm({
  data,
  onSubmit,
  onDelete,
  error: formError,
  breadcrumbs,
  isEdit = false,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [congregation, setCongregation] = useState(data?.congregacao || null);
  const [name, setName] = useState(data?.name || "");
  const [email, setEmail] = useState(data?.email || "");
  const [phone, setPhone] = useState(data?.phone || "");
  const [prefContact, setPrefContact] = useState(data?.prefContact || "EMAIL");
  const [tipo, setTipo] = useState(data?.tipo || "SAUDE");
  const [pedido, setPedido] = useState(data?.pedido || "");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await onSubmit({
        congregacaoId: congregation?.id,
        name,
        email,
        phone,
        prefContact,
        tipo,
        pedido,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      {breadcrumbs}
      <Card component="form" onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <CongregationSelect
            required={true}
            initialValue={congregation}
            value={congregation}
            onChange={setCongregation}
          />

          <TextField
            label={t("Name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <TextField
            label={t("Email")}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <TextField
            label={t("Phone")}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />

          <FormControl required>
            <InputLabel>{t("Preferred contact method")}</InputLabel>
            <Select
              value={prefContact}
              onChange={(e) => setPrefContact(e.target.value)}
            >
              <MenuItem value="EMAIL">Email</MenuItem>
              <MenuItem value="PHONE">Phone</MenuItem>
            </Select>
          </FormControl>

          <FormControl required>
            <InputLabel>{t("Prayer Type")}</InputLabel>
            <Select value={tipo} onChange={(e) => setTipo(e.target.value)}>
              <MenuItem value="SAUDE">{t("Health")}</MenuItem>
              <MenuItem value="JUSTICA">{t("Justice")}</MenuItem>
              <MenuItem value="IMIGRACAO">{t("Immigration")}</MenuItem>
              <MenuItem value="FAMILIA">{t("Family")}</MenuItem>
              <MenuItem value="VIDA_ESPIRITUAL">{t("Spiritual Life")}</MenuItem>
              <MenuItem value="SALVACAO">{t("Salvation")}</MenuItem>
              <MenuItem value="RELACIONAMENTO">{t("Relationship")}</MenuItem>
              <MenuItem value="MINISTERIO">{t("Ministry")}</MenuItem>
              <MenuItem value="IGREJA">{t("Church")}</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label={t("Prayer Request")}
            multiline
            rows={4}
            value={pedido}
            onChange={(e) => setPedido(e.target.value)}
            required
          />

          {/* Show status as read-only in edit mode */}
          {isEdit && (
            <TextField label={t("Status")} value={t(data.status)} disabled />
          )}

          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Saving...") : t("Save")}
            </Button>

            {isEdit && onDelete && (
              <Button
                variant="outlined"
                color="error"
                size="large"
                onClick={onDelete}
              >
                {t("Delete")}
              </Button>
            )}
          </Stack>

          {formError && <ErrorAlert error={formError} />}
        </Stack>
      </Card>
    </Container>
  );
}
