import { useNavigate, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { invalidatePrayerRequestQuery } from "data/queries/queryPrayerRequestAll";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import PrayerRequestForm from "./components/PrayerRequestForm.react";
import fetch from "data/fetch";

export async function loader({ params: { id } }) {
  const response = await fetch(`/v1/pedido-oracao/${id}`);

  return {
    id,
    ...response.data,
  };
}

export default function PrayerRequestEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useLoaderData();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      await fetch(`/v1/pedido-oracao/${data.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          status: formData.status || data.status, // Use provided status or keep existing
        }),
      });

      invalidatePrayerRequestQuery();
      navigate("/prayer-requests");
    } catch (err) {
      setError(err);
    }
  };

  const onDelete = async () => {
    if (
      !window.confirm(t("Are you sure you want to delete this prayer request?"))
    ) {
      return;
    }

    try {
      await fetch(`/v1/pedido-oracao/${data.id}`, {
        method: "DELETE",
      });

      invalidatePrayerRequestQuery();
      navigate("/prayer-requests");
    } catch (err) {
      setError(err);
    }
  };

  return (
    <PrayerRequestForm
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      isEdit={true}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit Prayer Request")}
          links={[
            {
              name: t("Prayer Requests"),
              to: "/prayer-requests",
            },
            { name: data.name },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
