import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import PastorSelect from "shared/components/PastorSelect/PastorSelect.react";
import fetch from "data/fetch";

export default function PastoralVisitForm({
  data,
  onSubmit,
  onDelete,
  error: formError,
  breadcrumbs,
  isEdit = false,
}) {
  const { t } = useTranslation();
  const [date, setDate] = useState(data?.data ? moment(data.data) : moment());
  const [pastor, setPastor] = useState(data?.pastor ?? null);
  const [person, setPerson] = useState(data?.pessoa ?? null);
  const [personQuery, setPersonQuery] = useState("");
  const [comment, setComment] = useState(data?.comentario ?? "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [congregationMembers, setCongregationMembers] = useState([]);
  const [congregationId, setCongregationId] = useState(
    data?.pastor?.congregacao?.id ?? null,
  );

  // Handle congregation members loading
  useEffect(() => {
    if (!congregationId) {
      setCongregationMembers([]);
      if (!isEdit) {
        setPerson(null);
        setPersonQuery("");
      }
      return;
    }

    setIsLoading(true);
    fetch(`/v1/congregacao/${congregationId}/pessoa`)
      .then((response) => {
        setCongregationMembers(response.data || []);
      })
      .catch((err) => {
        console.error("Error fetching congregation members:", err);
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [congregationId, isEdit]);

  const handlePastorChange = (newPastor) => {
    setPastor(newPastor);
    setCongregationId(newPastor?.congregacao?.id ?? null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await onSubmit({
        pastorId: pastor?.id,
        pessoaId: person?.id,
        data: date.format("YYYY-MM-DD"),
        comentario: comment,
      });
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      {breadcrumbs}
      <Card component="form" onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <DatePicker
            required={true}
            label={t("Date")}
            value={date}
            onChange={setDate}
          />

          <PastorSelect value={pastor} onChange={handlePastorChange} required />

          <Autocomplete
            required={true}
            value={person}
            inputValue={personQuery}
            options={congregationMembers}
            loading={isLoading}
            autoHighlight={true}
            getOptionLabel={(option) => option?.name ?? ""}
            onChange={(_event, newValue) => {
              setPerson(newValue);
            }}
            onInputChange={(_event, newInputValue) => {
              setPersonQuery(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Person")}
                required={true}
                disabled={!congregationId || isLoading}
                helperText={!pastor ? t("Select a pastor first") : ""}
              />
            )}
          />

          <TextField
            required={true}
            label={t("Comment")}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={4}
          />

          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting || isEdit}
              type="submit"
            >
              {isSubmitting ? t("Saving...") : t("Save")}
            </Button>

            {onDelete && (
              <Button
                variant="outlined"
                color="error"
                size="large"
                onClick={onDelete}
              >
                {t("Delete")}
              </Button>
            )}
          </Stack>

          {(error || formError) && <ErrorAlert error={error || formError} />}
        </Stack>
      </Card>
    </Container>
  );
}
