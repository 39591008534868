import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { invalidateWarRoomDetailQuery } from "data/queries/queryWarRoom";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import WarRoomForm from "./components/WarRoomForm.react";
import fetch from "data/fetch";

export default function WarRoomNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      // Restructure the data to match the required payload
      const payload = {
        comandanteId: formData.commander.id,
        congregacaoId: formData.congregation.id,
        horarioReuniao: formData.meetingTime,
        membros: formData.members.map((member) => member.id),
      };

      await fetch("/v1/quarto-guerra", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      invalidateWarRoomDetailQuery();
      navigate("/war-room");
    } catch (err) {
      setError(err);
    }
  };

  return (
    <WarRoomForm
      onSubmit={onSubmit}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("New War Room")}
          links={[
            {
              name: t("War Room"),
              to: "/war-room",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
