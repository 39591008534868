import { Link as RouterLink, useParams, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Link from "@mui/material/Link";

import {
  ensureWarRoomDetailQueryData,
  useWarRoomDetailQuery,
} from "data/queries/queryWarRoom";
import {
  ensureWarRoomMembersQueryData,
  useWarRoomMembersQuery,
} from "data/queries/queryWarRoomMembers";
import {
  ensureWarRoomPrayerRequestsQueryData,
  useWarRoomPrayerRequestsQuery,
} from "data/queries/queryWarRoomPrayerRequests";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { invalidatePrayerRequestQuery } from "data/queries/queryPrayerRequestAll";

export async function loader({ params: { id } }) {
  const [warRoomData, membersData] = await Promise.all([
    ensureWarRoomDetailQueryData(id),
    ensureWarRoomMembersQueryData(id),
  ]);
  return { warRoomData, membersData };
}

export default function WarRoomViewPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { warRoomData, membersData } = useLoaderData();

  const { data: warRoom } = useWarRoomDetailQuery({
    id,
    initialData: warRoomData,
  });

  const { data: members } = useWarRoomMembersQuery({
    id,
    initialData: membersData,
  });

  const { data: prayerRequests } = useWarRoomPrayerRequestsQuery({ id });

  return (
    <Container maxWidth="lg">
      <DetailsPageHeader
        routeIndex="/war-room"
        routeUpdate={`/war-room/${id}/edit`}
        heading={t("War Room")}
        links={[
          {
            name: t("War Room"),
            to: "/war-room",
          },
          { name: warRoom?.data?.comandante?.name ?? "..." },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RoomDetails warRoom={warRoom?.data} />
        </Grid>
        <Grid item xs={12}>
          <MembersList members={members?.data} />
        </Grid>
        <Grid item xs={12}>
          <PrayerRequestsList
            prayerRequests={prayerRequests?.data}
            warRoomId={id}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

function RoomDetails({ warRoom }) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("Details")} />
      <CardContent>
        <Stack spacing={2}>
          <Typography>
            <strong>{t("Commander")}:</strong> {warRoom?.comandante?.name}
          </Typography>
          <Typography>
            <strong>{t("Congregation")}:</strong> {warRoom?.congregacao?.name}
          </Typography>
          <Typography>
            <strong>{t("Meeting Time")}:</strong> {warRoom?.horarioReuniao}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

function MembersList({ members = [] }) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("Members")} />
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Name")}</TableCell>
                <TableCell>{t("Email")}</TableCell>
                <TableCell>{t("Phone")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/members/${member.id}/view`}
                    >
                      {member.name}
                    </Link>
                  </TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>{member.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

function PrayerRequestsList({ prayerRequests = [], warRoomId }) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingId, setSubmittingId] = useState(null);

  const handleMarkAsPrayed = async (request) => {
    if (
      !window.confirm(
        t("Are you sure you want to mark this prayer request as prayed?"),
      )
    ) {
      return;
    }

    if (isSubmitting) return;

    setIsSubmitting(true);
    setSubmittingId(request.id);
    try {
      const requestBody = {
        congregacaoId: request.congregacao?.id,
        name: request.name,
        email: request.email,
        phone: request.phone,
        prefContact: request.prefContact,
        tipo: request.tipo,
        pedido: request.pedido,
        status: "ORADO",
      };

      const response = await fetch(`/api/v1/pedido-oracao/${request.id}`, {
        method: "PUT",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await Promise.all([
        invalidatePrayerRequestQuery(),
        ensureWarRoomPrayerRequestsQueryData(warRoomId),
      ]);

      window.location.reload();
    } catch (err) {
      console.error("Error updating prayer request:", err);
      alert(t("An error occurred while updating the prayer request"));
    } finally {
      setIsSubmitting(false);
      setSubmittingId(null);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "EM_ORACAO":
        return "warning";
      case "RESPONDIDA":
        return "success";
      case "AGUARDANDO":
        return "info";
      default:
        return "default";
    }
  };

  return (
    <Card>
      <CardHeader
        title={t("Prayer Requests")}
        action={
          <Button
            component={RouterLink}
            to={`/war-room/${warRoomId}/prayer-requests/new`}
            startIcon={<AddIcon />}
          >
            {t("New Prayer Request")}
          </Button>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Name")}</TableCell>
                <TableCell>{t("Prayer Type")}</TableCell>
                <TableCell>{t("Status")}</TableCell>
                <TableCell>{t("Request")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prayerRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/prayer-requests/${request.id}/view`}
                    >
                      {request.name}
                    </Link>
                  </TableCell>
                  <TableCell>{t(request.tipo)}</TableCell>
                  <TableCell>
                    <Chip
                      label={t(request.status)}
                      color={getStatusColor(request.status)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{request.pedido}</TableCell>
                  <TableCell>
                    {request.status === "EM_ORACAO" && (
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={isSubmitting && submittingId === request.id}
                        onClick={() => handleMarkAsPrayed(request)}
                      >
                        {isSubmitting && submittingId === request.id
                          ? t("Updating...")
                          : t("Mark as Prayed")}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
