import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchPrayerRequests(page = 0, filter = "", sort = "-name") {
  return fetch(
    `/v1/pedido-oracao?size=100&index=${page}&name=${encodeURIComponent(filter)}&s=${sort}`,
  );
}

const getQueryKey = (index = 0, name = "", sort = "-name") => [
  "pedido-oracao",
  index,
  name,
  sort,
];

export function usePrayerRequestQuery({
  page = 0,
  filter = "",
  sort = "-name",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchPrayerRequests(page, filter, sort),
  });
}

export function ensurePrayerRequestQueryData({
  page = 0,
  filter = "",
  sort = "-name",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchPrayerRequests(page, filter, sort),
  });
}

export function invalidatePrayerRequestQuery() {
  return queryClient.invalidateQueries(["pedido-oracao"]);
}
