import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import PastorSelect from "shared/components/PastorSelect/PastorSelect.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import { useEBDClasseAvailableMembers } from "data/queries/queryEBDClasseAvailableMembers";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ensureCongregationsQueryData } from "data/queries/congregation/queryCongregations";

function AddMemberSelect({
  congregationId,
  onChange,
  onInputChange,
  required,
  label,
  value = null,
  inputValue = "",
  filterRole,
  disabled = false,
}) {
  const { t } = useTranslation();
  const { data = [] } = useEBDClasseAvailableMembers(congregationId);

  const [localInputValue, setLocalInputValue] = useState(inputValue || "");

  return (
    <Autocomplete
      required={required}
      value={value}
      inputValue={localInputValue}
      options={data}
      autoHighlight={true}
      getOptionLabel={(option) => option?.name || ""}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setLocalInputValue(newInputValue);
        onInputChange?.(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label ?? t("Add a member")} />
      )}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
    />
  );
}

export default function WarRoomForm({
  data,
  onSubmit,
  onDelete,
  error: formError,
  breadcrumbs,
  isEdit = false,
}) {
  const { t } = useTranslation();

  // Initialize state with data from props
  const [congregation, setCongregation] = useState(
    data?.congregation
      ? {
          id: data.congregation.id,
          name: data.congregation.name,
          country: data.congregation.country,
          regional: data.congregation.regional,
        }
      : null,
  );
  const [commander, setCommander] = useState(data?.commander ?? null);
  const [commanderQuery, setCommanderQuery] = useState(
    data?.commander?.name ?? "",
  );
  const [meetingTime, setMeetingTime] = useState(data?.meetingTime ?? "");
  const [members, setMembers] = useState(data?.members ?? []);
  const [memberInputValue, setMemberInputValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  // Ensure congregation data is loaded
  useEffect(() => {
    ensureCongregationsQueryData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEdit && (!congregation || !commander || !meetingTime)) {
      setError(new Error(t("All fields are required")));
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      await onSubmit({
        congregation,
        commander,
        meetingTime,
        members,
      });
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      {breadcrumbs}
      <Card component="form" onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <CongregationSelect
            required={true}
            initialValue={
              data?.congregation
                ? {
                    id: data.congregation.id,
                    name: data.congregation.name,
                    country: data.congregation.country,
                    regional: data.congregation.regional,
                  }
                : null
            }
            value={congregation}
            onChange={(newCongregation) => {
              setCongregation(newCongregation);
              setCommander(null);
              setCommanderQuery("");
            }}
            disabled={isEdit}
          />

          <AddMemberSelect
            required={true}
            label={t("Commander")}
            congregationId={congregation?.id}
            value={commander}
            inputValue={commanderQuery}
            onChange={(newValue) => {
              setCommander(newValue);
            }}
            onInputChange={(newValue) => setCommanderQuery(newValue)}
            filterRole="PASTOR"
            disabled={isEdit}
          />

          <TextField
            required={true}
            label={t("Meeting Time")}
            value={meetingTime}
            onChange={(e) => setMeetingTime(e.target.value)}
            disabled={isEdit}
          />

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              {t("Members")}
            </Typography>
            <AddMemberSelect
              congregationId={congregation?.id}
              value={null}
              inputValue={memberInputValue}
              onChange={(newValue) => {
                if (newValue) {
                  setMembers([...members, newValue]);
                }
                setMemberInputValue("");
              }}
              onInputChange={(newValue) => setMemberInputValue(newValue)}
            />
            <List>
              {members.map((member) => (
                <ListItem
                  key={member.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() =>
                        setMembers(members.filter((m) => m.id !== member.id))
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>{member.name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={member.name} />
                </ListItem>
              ))}
            </List>
          </Box>

          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Saving...") : t("Save")}
            </Button>

            {isEdit && onDelete && (
              <Button
                variant="outlined"
                color="error"
                size="large"
                onClick={onDelete}
              >
                {t("Delete")}
              </Button>
            )}
          </Stack>

          {(error || formError) && <ErrorAlert error={error || formError} />}
        </Stack>
      </Card>
    </Container>
  );
}
